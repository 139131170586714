
class ExpandRep {
    expand_repetition = function (cell, elem) {
        if (elem.repetition.type != QGdstk.RepetitionType.None) {
            cell.add(elem.apply_repetition())
        }
    }

    recru_expand_ref = function (ref) {
        if (ref.cell) {
            this.expand_cell_repetition(ref.cell)
        }
    }


    expand_cell_repetition = function (cell) {
        cell.polygons.forEach(elem => {
            this.expand_repetition(cell, elem);
        });

        cell.paths.forEach(elem => {
            this.expand_repetition(cell, elem);
        });

        cell.labels.forEach(elem => {
            this.expand_repetition(cell, elem);
        });

        cell.references.forEach(elem => {
            this.recru_expand_ref(elem);
            this.expand_repetition(cell, elem);
        });
    }
}



export function expand_repetition(gds_lib) {
    let expander = new ExpandRep();
    gds_lib.cells.forEach(cell => {
        expander.expand_cell_repetition(cell);
    });
}

